.welcome {
	position: relative;

	&__stomach {
		width: vw(650px, 750);

		@include minW(992) {
			width: vw(1097px);
		}

		@include minW(1441) {
			width: 1097px;
		}
	}

	&__main {
		position: relative;
		overflow: hidden;
	}

	&__baby {
		position: absolute;
		top: vw(140px, 750);
		left: vw(60px, 750);
		width: vw(300px, 750);
		z-index: 2;

		@include minW(992) {
			top: vw(250px);
			left: vw(200px);
			width: vw(445px);
		}

		@include minW(1441) {
			width: 445px;
		}
	}

	.baby-cloud {
		position: absolute;
		left: vw(320px, 750);
		top: vw(50px, 750);
		width: vw(440px, 750);
		z-index: 3;

		@include minW(992) {
			left: vw(580px);
			top: vw(100px);
			width: vw(724px);
		}

		@include minW(1441) {
			left: 690px;
			width: 724px;
			top: 0;
		}
	}

	.cloud {
		position: absolute;
		width: 100%;

		&--top {
			top: 0;
		}

		&--bot {
			bottom: 0;
		}
	}
}

.spritespin1,
.spritespin2,
.spritespin3 {
	pointer-events: none;
	cursor: default;
}
