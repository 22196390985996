.expert {
	position: relative;
	overflow: hidden;
	margin-bottom: vw(100px, 750);
	margin-top: vw(-200px, 750);
	z-index: 2;

	@include minW(992) {
		margin-bottom: vw(100px);
		margin-top: vw(-350px);
	}

	@include minW(1441) {
		margin-bottom: 100px;
		margin-top: -350px;
	}

	.title {
		text-align: center;
	}

	.slick {
		&-list {
			margin: 0 vw(-32px, 750);
			padding: vw(50px, 750) vw(150px, 750) 0 !important;

			@include minW(992) {
				margin: 0 vw(-32px);
				padding: vw(50px) vw(296px) 0 !important;
			}

			@include minW(1441) {
				margin: 0 -32px;
				padding: 50px 296px 0 !important;
			}
		}

		&-slide {
			padding: vw(32px, 750);
			opacity: 0.3;

			@include minW(992) {
				padding: vw(32px);
			}

			@include minW(1441) {
				padding: 32px;
			}
		}

		&-center {
			opacity: 1;
		}

		&-arrow {
			top: 58%;
			transform: translateY(-58%);
		}

		&-next {
			right: vw(100px, 750);

			@include minW(992) {
				right: vw(210px);
			}

			@include minW(1441) {
				right: 210px;
			}
		}

		&-prev {
			left: vw(100px, 750);

			@include minW(992) {
				left: vw(210px);
			}

			@include minW(1441) {
				left: 210px;
			}
		}

		&__item {
			padding: 0 16px;
			max-width: 900px;
			margin: 0 auto;
		}
	}
}
