.news-detail {
	position: relative;
	overflow: hidden;
	z-index: 1;

	&__main {
		position: relative;
		z-index: 2;
	}

	&__body {
		padding: vw(160px, 750) 0 0;
		font-size: vw(24px, 750);

		@include minW(992) {
			padding: vw(160px) vw(100px) 0;
			font-size: vw(18px);
		}

		@include minW(1441) {
			padding: 160px 100px 0;
			font-size: 18px;
		}

		ul {
			padding-left: 17px;

			ol {
				padding-left: 17px;
				list-style-type: circle;
			}
		}
	}

	&__title {
		font-size: vw(36px, 750);
		margin-bottom: vw(20px, 750);
		color: $turquoise-color;
		text-transform: uppercase;

		@include minW(992) {
			font-size: vw(30px);
			margin-bottom: vw(15px);
		}

		@include minW(1441) {
			font-size: 30px;
			margin-bottom: 15px;
		}
	}

	&__date {
		font-style: italic;
	}

	&__line {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: vw(40px, 750);

		@include minW(992) {
			margin-bottom: vw(60px);
		}

		@include minW(1441) {
			margin-bottom: 60px;
		}

		&--foot {
			border-top: 1px solid #ccc;
			padding-top: vw(30px, 750);
			margin-top: vw(60px, 750);
			justify-content: flex-end;

			@include minW(992) {
				padding-top: vw(30px);
				margin-top: vw(60px);
			}

			@include minW(1441) {
				padding-top: 30px;
				margin-top: 60px;
			}
		}
	}

	.bg-news-detail {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
	}
}

.share {
	position: relative;
	display: flex;
	align-items: center;

	.social {
		display: flex;
		align-items: center;

		a {
			padding-left: vw(20px, 750);
			margin-left: vw(20px, 750);

			@include minW(992) {
				padding-left: vw(10px);
				margin-left: vw(10px);
			}

			@include minW(1441) {
				padding-left: 10px;
				margin-left: 10px;
			}

			&:not(:first-child) {
				border-left: 1px solid #ccc;
			}

			&:first-child {
				.img {
					width: vw(16px, 750);

					@include minW(992) {
						width: vw(12px);
					}

					@include minW(1441) {
						width: 12px;
					}
				}
			}

			&:last-child {
				.img {
					width: vw(28px, 750);

					@include minW(992) {
						width: vw(23px);
					}

					@include minW(1441) {
						width: 23px;
					}
				}
			}
		}

		&__link {
			display: block;
			position: relative;

			span {
				position: absolute;
				top: -30px;
				left: 0;
				font-size: 10px;
				display: block;
				width: 50px;
				background-color: #ccc;
				text-align: center;
				padding: 3px;
				border-radius: 3px;
				opacity: 0;

				&::after {
					content: '';
					display: block;
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-top: 5px solid #ccc;
					position: absolute;
					bottom: -5px;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}

	}
}
