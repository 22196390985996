.thank-you {
	position: relative;
	overflow: hidden;
	background: -moz-linear-gradient(top, rgba(166, 238, 241, 0) 0%, rgba(104, 225, 231, 1) 50%, rgba(228, 255, 254, 0) 100%);
	background: -webkit-linear-gradient(top, rgba(166, 238, 241, 0) 0%, rgba(104, 225, 231, 1) 50%, rgba(228, 255, 254, 0) 100%);
	background: linear-gradient(to bottom, rgba(166, 238, 241, 0) 0%, rgba(104, 225, 231, 1) 50%, rgba(228, 255, 254, 0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00a6eef1', endColorstr='#00e4fffe', GradientType=0);
	padding: vw(100px, 750) 0 vw(50px, 750);

	@include minW(992) {
		padding: vw(120px) 0 vw(300px);
	}

	@include minW(1441) {
		padding: 120px 0 300px;
	}

	&__body {
		position: relative;
		z-index: 2;

		.row {
			align-items: flex-end;
		}
	}

	.stomach {
		position: relative;
		width: vw(458px, 750);

		@include minW(992) {
			width: vw(458px);
		}

		@include minW(1441) {
			width: 458px;
		}
	}

	.baby {
		position: absolute;
		width: vw(369px, 750);
		top: vw(230px, 750);
		bottom: auto;
		z-index: 2;

		@include minW(992) {
			position: absolute;
			top: auto;
			width: vw(492px);
			bottom: vw(60px);
		}

		@include minW(1441) {
			width: 492px;
			bottom: 60px;
		}
	}

	.product {
		position: absolute;
		display: flex;
		justify-content: center;
		right: vw(30px, 750);
		top: vw(200px, 750);
		bottom: auto;
		left: auto;
		z-index: 2;

		@include minW(992) {
			position: absolute;
			right: auto;
			top: auto;
			bottom: vw(70px);
			left: vw(520px);
		}

		@include minW(1441) {
			bottom: 100px;
			left: 520px;
		}

		.img {
			width: vw(240px, 750);

			@include minW(992) {
				width: vw(381px);
			}

			@include minW(1441) {
				width: 381px;
			}
		}
	}

	.cloud {
		position: absolute;
		width: 100%;
		bottom: 0;
	}
}

.shopping {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: vw(80px, 750) 0 vw(40px, 750);
	z-index: 2;

	@include minW(992) {
		position: absolute;
		width: vw(450px);
		right: vw(90px);
		bottom: vw(120px);
	}

	@include minW(1441) {
		width: 450px;
		right: 90px;
		bottom: 120px;
	}

	&__logo {
		width: vw(280px, 750);

		@include minW(992) {
			width: vw(323px);
		}

		@include minW(1441) {
			width: 323px;
		}
	}

	&__buy {
		width: vw(500px, 750);
		margin-bottom: vw(20px, 750);

		@include minW(992) {
			width: vw(414px);
			margin-bottom: vw(20px);
		}

		@include minW(1441) {
			width: 414px;
			margin-bottom: 20px;
		}
	}
}

.trading {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	list-style: none;
	width: vw(500px, 750);

	@include minW(992) {
		flex-direction: column;
	}

	&__item {
		transition: all 0.3s ease-out;
		width: 50%;

		@include minW(992) {
			width: vw(234px);
		}

		@include minW(1441) {
			width: 234px;
		}

		@include hover {
			transform: scale(1.05);
		}
	}
}
