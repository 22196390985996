.header {
	position: fixed;
	width: 100%;
	background: -moz-linear-gradient(top, rgba(243, 254, 255, 1) 0%, rgba(243, 254, 255, 1) 65%, rgba(243, 254, 255, 0) 100%);
	background: -webkit-linear-gradient(top, rgba(243, 254, 255, 1) 0%, rgba(243, 254, 255, 1) 65%, rgba(243, 254, 255, 0) 100%);
	background: linear-gradient(to bottom, rgba(243, 254, 255, 1) 0%, rgba(243, 254, 255, 1) 65%, rgba(243, 254, 255, 0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3feff', endColorstr='#00f3feff', GradientType=0);
	z-index: 99;

	&::before {
		content: '';
		display: block;
		width: 100%;
		height: vw(60px, 750);
		background: $turquoise-color;
		border-radius: 0 0 10px 10px;
		box-shadow: 0 3px 6px rgba($black-color, 0.25);
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		transition: opacity 0.3s ease-out;

		@include minW(992) {
			height: vw(66px);
			display: block;
		}

		@include minW(1441) {
			height: 66px;
		}
	}

	&.is-sticky {
		background: none;

		&::before {
			opacity: 1;
		}

		.menu {
			&__item {
				&:last-child {
					.menu {
						&__link {
							@include minW(992) {
								background-image: url('..//images/logo-mega-2.png');
							}
						}
					}
				}
			}

			&__link {

				@include minW(992) {
					color: $white-color;
				}

				&::after {
					background-color: $white-color;
				}
			}
		}

		.burger {
			&__icon {
				background-color: #fff;

				&::before,
				&::after {
					background-color: #fff;
				}
			}
		}
	}

	&.is-show {
		.menu {
			transform: translateX(0);
		}

		.burger {
			&__icon {
				background-color: transparent;

				&::before,
				&::after {
					top: 0;
					background-color: $turquoise-color;
				}

				&::before {
					transform: rotate(45deg);
				}

				&::after {
					transform: rotate(-45deg);
				}
			}
		}
	}

	&__main {
		position: relative;
		z-index: 2;
	}

	&__body {
		position: relative;
		display: flex;
		justify-content: flex-end;
	}

	.logo {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: vw(200px, 750);
		z-index: 2;

		@include minW(992) {
			width: vw(215px);
		}

		@include minW(1441) {
			width: 215px;
		}
	}

	.menu {
		list-style: none;
		padding: vw(6px, 750) 0 vw(25px, 750);
		margin-bottom: 0;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		padding: vw(100px, 750) vw(32px, 750) vw(32px, 750);
		transform: translateX(100%);
		background-color: #fff;
		overflow: hidden;
		overflow-y: auto;
		transition: all 0.3s ease-out;

		@include minW(992) {
			position: relative;
			transition: none;
			transform: none;
			top: auto;
			left: auto;
			right: auto;
			bottom: auto;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			background-color: transparent;
			overflow: unset;
			padding: vw(14px) 0 vw(34px);
		}

		@include minW(1441) {
			padding: 14px 0 34px;
		}

		&__item {
			display: flex;
			justify-content: center;
			margin-bottom: vw(30px, 750);

			@include minW(992) {
				display: block;
				margin-left: vw(30px);
			}

			@include minW(1441) {
				margin-left: 30px;
			}

			&:last-child {
				.menu {
					&__link {
						width: vw(200px, 750);
						height: vw(53px, 750);
						background-image: url('../images/logo-mega-1.png');
						background-repeat: no-repeat;
						background-position: center;
						background-size: cover;

						@include minW(992) {
							width: vw(150px);
							height: vw(40px);
						}

						@include minW(1441) {
							width: 150px;
							height: 40px;
						}

						&::after {
							display: none;
						}
					}
				}
			}


			&.is-active {
				.menu {
					&__link {
						font-weight: 700;

						&::after {
							width: 100%;
						}
					}
				}
			}

			@include hover {
				.menu {
					&__link {
						&::after {
							width: 100%;
						}
					}
				}
			}
		}

		&__link {
			position: relative;
			display: block;
			font-size: vw(30px, 750);
			font-weight: 400;
			color: $turquoise-color;
			transition: color 0.3s ease-out;

			@include minW(992) {
				font-size: vw(20px);
			}

			@include minW(1441) {
				font-size: 20px;
			}

			&::after {
				content: '';
				display: block;
				width: 0;
				height: 1px;
				background-color: $turquoise-color;
				position: absolute;
				left: 50%;
				bottom: 0;
				transform: translateX(-50%);
				transition: width 0.3s ease-out;
			}
		}
	}

	.burger {
		position: absolute;
		right: 0;
		top: vw(8px, 750);
		width: vw(40px, 750);
		height: vw(40px, 750);
		background-color: transparent;
		border: 0;
		outline: none;
		display: block;

		@include minW(992) {
			display: none;
		}

		&:focus,
		&:active {
			outline: none;
		}

		&__icon {
			display: block;
			width: vw(40px, 750);
			height: 2px;
			background-color: $turquoise-color;
			position: relative;
			transition: all 0.3s ease-out;

			&::before,
			&::after {
				content: '';
				display: block;
				width: vw(40px, 750);
				height: 2px;
				background-color: $turquoise-color;
				position: absolute;
				transition: all 0.3s ease-out;
			}

			&::before {
				top: vw(-12px, 750);
			}

			&::after {
				top: vw(12px, 750);
			}
		}
	}
}
