.nature {
	position: relative;

	&__main {
		position: relative;
		overflow: hidden;
		z-index: 3;
	}

	&__head {
		display: flex;
		justify-content: center;

		.img {
			width: vw(597px, 750);

			@include minW(992) {
				width: vw(597px);
			}

			@include minW(1441) {
				width: 597px;
			}
		}
	}

	&__body {
		margin: 0 auto;
		font-size: vw(24px, 750);

		@include minW(992) {
			width: vw(874px);
			font-size: vw(24px);
		}

		@include minW(1441) {
			width: 874px;
			font-size: 24px;
		}

		.row {
			align-items: center;
		}
	}

	&__thumb {
		display: flex;
		justify-content: center;
		transition: transform 0.3s ease-out;

		@include hover {
			transform: scale(1.05);
		}
	}

	.tree {
		position: absolute;
		width: 100%;
		top: vw(-150px);
		z-index: 2;
	}

	&__content {
		text-align: center;
		padding: vw(15px, 750) 0;

		@include minW(992) {
			text-align: left;
			padding: 0;
		}
	}

	.cloud {
		position: absolute;
		width: 100%;
		top: vw(-236px);
	}
}
