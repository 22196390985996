.supports {
	position: relative;
	background-color: #8aedeb;
	padding: vw(120px) 0 vw(236px);
	overflow: hidden;

	&__head {
		position: relative;
		margin-bottom: 16px;

		img {
			margin: 0 auto;
			max-width: vw(650px, 750);

			@include minW(992) {
				max-width: vw(1162px);
			}

			@include minW(1441) {
				max-width: 1162px;
			}
		}
	}

	.row {
		justify-content: center;
	}
}
