.container {
	width: 100%;
	max-width: 1200px;
	padding: 0 15px;
	margin: 0 auto;

	&--fluid {
		max-width: 100%;
	}
}
