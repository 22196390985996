.reason {
	background: rgb(238, 253, 255);
	background: -moz-linear-gradient(top, rgba(238, 253, 255, 1) 0%, rgba(134, 212, 216, 1) 100%);
	background: -webkit-linear-gradient(top, rgba(238, 253, 255, 1) 0%, rgba(134, 212, 216, 1) 100%);
	background: linear-gradient(to bottom, rgba(238, 253, 255, 1) 0%, rgba(134, 212, 216, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eefdff', endColorstr='#86d4d8', GradientType=0);
	width: 100%;
	position: relative;
	padding-bottom: vw(600px);
	margin-top: vw(-120px, 750);
	z-index: 2;

	@include minW(992) {
		padding-bottom: vw(440px);
		margin-top: vw(-200px);
	}

	@include minW(1441) {
		margin-top: -200px;
		padding-bottom: 440px;
	}

	&__title {
		margin-bottom: vw(30px, 750);

		@include minW(992) {
			margin-bottom: vw(30px);
		}

		@include minW(1441) {
			margin-bottom: 30px;
		}

		.img {
			margin: 0 auto;
			width: vw(500px, 750);

			@include minW(992) {
				width: vw(1103px);
			}

			@include minW(1441) {
				width: 1103px;
			}
		}
	}

	&__head {
		width: 100%;
		max-width: vw(1162px, 750);
		margin: 0 auto vw(60px, 750);

		@include minW(992) {
			max-width: vw(1162px);
			margin: 0 auto vw(60px);
		}

		@include minW(1441) {
			max-width: 1163px;
			margin: 0 auto 60px;
		}
	}

	.method {
		position: relative;
		overflow: hidden;
		z-index: 2;
	}

	.cloud {
		position: absolute;
		width: 100%;

		&--top {
			top: vw(800px, 750);

			@include minW(992) {
				top: vw(500px);
			}

			@include minW(1441) {
				top: 500px;
			}
		}

		&--bot {
			bottom: 0;
		}
	}
}

.method-old {
	&__head {
		display: flex;
		justify-content: center;
		margin-bottom: vw(30px, 750);

		@include minW(992) {
			margin-bottom: vw(30px);
		}

		@include minW(1441) {
			margin-bottom: 30px;
		}

		.img {
			width: vw(903px, 750);

			@include minW(992) {
				width: vw(903px);
			}

			@include minW(1441) {
				width: 903px;
			}
		}
	}

	&__body {
		width: 100%;
		max-width: vw(780px, 750);
		margin: 0 auto;

		@include minW(992) {
			max-width: vw(780px);
		}

		@include minW(1441) {
			max-width: 780px;
		}

		.row {
			justify-content: center;
		}
	}
}

.method-new {
	position: relative;
	width: 100%;
	margin: 0 auto;

	@include minW(992) {
		max-width: vw(770px);
	}

	@include minW(1441) {
		max-width: 770px;
	}

	&__head {
		display: flex;
		justify-content: center;
		margin-bottom: vw(60px);

		.img {
			width: vw(120px, 750);

			@include minW(992) {
				width: vw(100px);
			}

			@include minW(1441) {
				width: 100px;
			}
		}
	}

	.xixi {
		position: absolute;

		&--one {
			top: 0;
			left: 0;
			width: vw(150px, 750);

			@include minW(992) {
				top: vw(160px);
				width: vw(179px);
				left: vw(-190px);
			}

			@include minW(1441) {
				top: 160px;
				width: 179px;
				left: -190px;
			}
		}

		&--two {
			top: vw(250px, 750);
			right: 0;
			width: vw(220px, 750);

			@include minW(992) {
				top: vw(210px);
				width: vw(252px);
				right: vw(-110px);
			}

			@include minW(1441) {
				width: 252px;
				right: -110px;
			}
		}
	}
}

.intro-product {
	text-align: center;

	@include minW(992) {
		text-align: left;
	}

	.row {
		align-items: center;
	}

	&__body {

		@include minW(992) {
			max-width: vw(510px);
		}

		@include minW(1441) {
			max-width: 510px;
		}
	}

	&__title {
		display: flex;
		justify-content: center;

		.img {
			width: vw(575px, 750);

			@include minW(992) {
				width: vw(575px);
			}

			@include minW(1441) {
				width: 575px;
			}
		}
	}

	&__thumb {
		width: vw(400px, 750);
		margin: 0 auto;
		padding: vw(100px, 750) 0 vw(50px, 750);

		@include minW(992) {
			width: vw(685px);
		}

		@include minW(1441) {
			width: 685px;
		}
	}

	&__list {
		list-style: none;
		padding: 0;
		margin-bottom: vw(30px, 750);

		@include minW(992) {
			margin-bottom: vw(30px);
		}

		@include minW(1441) {
			margin-bottom: 30px;
		}
	}

	&__item {
		position: relative;
		margin-bottom: vw(15px, 750);

		@include minW(992) {
			margin-bottom: vw(15px);
		}

		@include minW(1441) {
			margin-bottom: 15px;
		}

		span {
			position: relative;
			border-radius: vw(50px, 750);
			padding: 0 vw(30px, 750);
			font-size: vw(26px, 750);
			font-weight: 800;
			color: $turquoise-color;
			width: 100%;
			max-width: vw(550px, 750);
			display: inline-block;
			box-shadow: 0 0 10px rgba(#000000, 0.25);
			background: rgb(255, 255, 255);
			background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(224, 255, 254, 1) 100%);
			background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(224, 255, 254, 1) 100%);
			background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(224, 255, 254, 1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e0fffe', GradientType=0);

			@include minW(992) {
				border-radius: vw(50px);
				padding: 0 vw(30px);
				font-size: vw(26px);
			}

			@include minW(1441) {
				border-radius: 50px;
				padding: 0 30px;
				font-size: 26px;
			}

			&::before {
				content: '';
				display: block;
				width: vw(38px, 750);
				height: vw(32px, 750);
				background: url('../images/reason/checked.png') no-repeat center;
				background-size: cover;
				position: absolute;
				left: vw(-15px, 750);
				top: 50%;
				transform: translateY(-50%);

				@include minW(992) {
					width: vw(38px);
					height: vw(32px);
					left: vw(-15px);
				}

				@include minW(1441) {
					width: 38px;
					height: 32px;
					left: -15px;
				}
			}
		}
	}

	&__button {
		display: flex;
		justify-content: center;

		.btn {
			width: vw(212px, 750);
			height: vw(64px, 750);
			font-size: vw(30px, 750);
			background: url('../images/reason/bg-button-dark.png') no-repeat center;
			background-size: cover;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: $second-font;
			letter-spacing: -2%;
			color: $white-color;
			transition: all 0.3s ease-out;

			@include minW(992) {
				width: vw(212px);
				height: vw(64px);
				font-size: vw(30px);
			}

			@include minW(1441) {
				width: 212px;
				height: 64px;
				font-size: 30px;
			}

			@include hover {
				transform: scale(1.05);
			}
		}
	}
}
