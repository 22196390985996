.row {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -15px;

	&__col {
		flex-shrink: 0;
		width: 100%;
		max-width: 100%;
		padding: 0 15px;

		&--auto {
			flex: 0 0 auto;
			width: auto;
		}

		&--1 {
			flex: 0 0 auto;
			width: 8.33333333%;
		}

		&--2 {
			flex: 0 0 auto;
			width: 16.66666667%;
		}

		&--3 {
			flex: 0 0 auto;
			width: 25%;
		}

		&--4 {
			flex: 0 0 auto;
			width: 33.33333333%;
		}

		&--5 {
			flex: 0 0 auto;
			width: 41.66666667%;
		}

		&--6 {
			flex: 0 0 auto;
			width: 50%;
		}

		&--7 {
			flex: 0 0 auto;
			width: 58.33333333%;
		}

		&--8 {
			flex: 0 0 auto;
			width: 66.66666667%;
		}

		&--9 {
			flex: 0 0 auto;
			width: 75%;
		}

		&--10 {
			flex: 0 0 auto;
			width: 83.33333333%;
		}

		&--11 {
			flex: 0 0 auto;
			width: 91.66666667%;
		}

		&--12 {
			flex: 0 0 auto;
			width: 100%;
		}

		&--offset {
			&-1 {
				margin-left: 8.33333333%;
			}

			&-2 {
				margin-left: 16.66666667%;
			}

			&-3 {
				margin-left: 25%;
			}

			&-4 {
				margin-left: 33.33333333%;
			}

			&-5 {
				margin-left: 41.66666667%;
			}

			&-6 {
				margin-left: 50%;
			}

			&-7 {
				margin-left: 58.33333333%;
			}

			&-8 {
				margin-left: 66.66666667%;
			}

			&-9 {
				margin-left: 75%;
			}

			&-10 {
				margin-left: 83.33333333%;
			}

			&-11 {
				margin-left: 91.66666667%;
			}
		}
	}
}

@include minW(576) {
	.row {
		&__col {
			&--sm {
				&-auto {
					flex: 0 0 auto;
					width: auto;
				}

				&-1 {
					flex: 0 0 auto;
					width: 8.33333333%;
				}

				&-2 {
					flex: 0 0 auto;
					width: 16.66666667%;
				}

				&-3 {
					flex: 0 0 auto;
					width: 25%;
				}

				&-4 {
					flex: 0 0 auto;
					width: 33.33333333%;
				}

				&-5 {
					flex: 0 0 auto;
					width: 41.66666667%;
				}

				&-6 {
					flex: 0 0 auto;
					width: 50%;
				}

				&-7 {
					flex: 0 0 auto;
					width: 58.33333333%;
				}

				&-8 {
					flex: 0 0 auto;
					width: 66.66666667%;
				}

				&-9 {
					flex: 0 0 auto;
					width: 75%;
				}

				&-10 {
					flex: 0 0 auto;
					width: 83.33333333%;
				}

				&-11 {
					flex: 0 0 auto;
					width: 91.66666667%;
				}

				&-12 {
					flex: 0 0 auto;
					width: 100%;
				}

				&-offset {
					&-1 {
						margin-left: 8.33333333%;
					}

					&-2 {
						margin-left: 16.66666667%;
					}

					&-3 {
						margin-left: 25%;
					}

					&-4 {
						margin-left: 33.33333333%;
					}

					&-5 {
						margin-left: 41.66666667%;
					}

					&-6 {
						margin-left: 50%;
					}

					&-7 {
						margin-left: 58.33333333%;
					}

					&-8 {
						margin-left: 66.66666667%;
					}

					&-9 {
						margin-left: 75%;
					}

					&-10 {
						margin-left: 83.33333333%;
					}

					&-11 {
						margin-left: 91.66666667%;
					}
				}
			}
		}
	}
}

@include minW(768) {
	.row {
		&__col {
			&--md {
				&-auto {
					flex: 0 0 auto;
					width: auto;
				}

				&-1 {
					flex: 0 0 auto;
					width: 8.33333333%;
				}

				&-2 {
					flex: 0 0 auto;
					width: 16.66666667%;
				}

				&-3 {
					flex: 0 0 auto;
					width: 25%;
				}

				&-4 {
					flex: 0 0 auto;
					width: 33.33333333%;
				}

				&-5 {
					flex: 0 0 auto;
					width: 41.66666667%;
				}

				&-6 {
					flex: 0 0 auto;
					width: 50%;
				}

				&-7 {
					flex: 0 0 auto;
					width: 58.33333333%;
				}

				&-8 {
					flex: 0 0 auto;
					width: 66.66666667%;
				}

				&-9 {
					flex: 0 0 auto;
					width: 75%;
				}

				&-10 {
					flex: 0 0 auto;
					width: 83.33333333%;
				}

				&-11 {
					flex: 0 0 auto;
					width: 91.66666667%;
				}

				&-12 {
					flex: 0 0 auto;
					width: 100%;
				}

				&-offset {
					&-1 {
						margin-left: 8.33333333%;
					}

					&-2 {
						margin-left: 16.66666667%;
					}

					&-3 {
						margin-left: 25%;
					}

					&-4 {
						margin-left: 33.33333333%;
					}

					&-5 {
						margin-left: 41.66666667%;
					}

					&-6 {
						margin-left: 50%;
					}

					&-7 {
						margin-left: 58.33333333%;
					}

					&-8 {
						margin-left: 66.66666667%;
					}

					&-9 {
						margin-left: 75%;
					}

					&-10 {
						margin-left: 83.33333333%;
					}

					&-11 {
						margin-left: 91.66666667%;
					}
				}
			}
		}
	}
}

@include minW(992) {
	.row {
		&__col {
			&--lg {
				&-auto {
					flex: 0 0 auto;
					width: auto;
				}

				&-1 {
					flex: 0 0 auto;
					width: 8.33333333%;
				}

				&-2 {
					flex: 0 0 auto;
					width: 16.66666667%;
				}

				&-3 {
					flex: 0 0 auto;
					width: 25%;
				}

				&-4 {
					flex: 0 0 auto;
					width: 33.33333333%;
				}

				&-5 {
					flex: 0 0 auto;
					width: 41.66666667%;
				}

				&-6 {
					flex: 0 0 auto;
					width: 50%;
				}

				&-7 {
					flex: 0 0 auto;
					width: 58.33333333%;
				}

				&-8 {
					flex: 0 0 auto;
					width: 66.66666667%;
				}

				&-9 {
					flex: 0 0 auto;
					width: 75%;
				}

				&-10 {
					flex: 0 0 auto;
					width: 83.33333333%;
				}

				&-11 {
					flex: 0 0 auto;
					width: 91.66666667%;
				}

				&-12 {
					flex: 0 0 auto;
					width: 100%;
				}

				&-offset {
					&-1 {
						margin-left: 8.33333333%;
					}

					&-2 {
						margin-left: 16.66666667%;
					}

					&-3 {
						margin-left: 25%;
					}

					&-4 {
						margin-left: 33.33333333%;
					}

					&-5 {
						margin-left: 41.66666667%;
					}

					&-6 {
						margin-left: 50%;
					}

					&-7 {
						margin-left: 58.33333333%;
					}

					&-8 {
						margin-left: 66.66666667%;
					}

					&-9 {
						margin-left: 75%;
					}

					&-10 {
						margin-left: 83.33333333%;
					}

					&-11 {
						margin-left: 91.66666667%;
					}
				}
			}
		}
	}
}

@include minW(1200) {
	.row {
		&__col {
			&--xl {
				&-auto {
					flex: 0 0 auto;
					width: auto;
				}

				&-1 {
					flex: 0 0 auto;
					width: 8.33333333%;
				}

				&-2 {
					flex: 0 0 auto;
					width: 16.66666667%;
				}

				&-3 {
					flex: 0 0 auto;
					width: 25%;
				}

				&-4 {
					flex: 0 0 auto;
					width: 33.33333333%;
				}

				&-5 {
					flex: 0 0 auto;
					width: 41.66666667%;
				}

				&-6 {
					flex: 0 0 auto;
					width: 50%;
				}

				&-7 {
					flex: 0 0 auto;
					width: 58.33333333%;
				}

				&-8 {
					flex: 0 0 auto;
					width: 66.66666667%;
				}

				&-9 {
					flex: 0 0 auto;
					width: 75%;
				}

				&-10 {
					flex: 0 0 auto;
					width: 83.33333333%;
				}

				&-11 {
					flex: 0 0 auto;
					width: 91.66666667%;
				}

				&-12 {
					flex: 0 0 auto;
					width: 100%;
				}

				&-offset {
					&-1 {
						margin-left: 8.33333333%;
					}

					&-2 {
						margin-left: 16.66666667%;
					}

					&-3 {
						margin-left: 25%;
					}

					&-4 {
						margin-left: 33.33333333%;
					}

					&-5 {
						margin-left: 41.66666667%;
					}

					&-6 {
						margin-left: 50%;
					}

					&-7 {
						margin-left: 58.33333333%;
					}

					&-8 {
						margin-left: 66.66666667%;
					}

					&-9 {
						margin-left: 75%;
					}

					&-10 {
						margin-left: 83.33333333%;
					}

					&-11 {
						margin-left: 91.66666667%;
					}
				}
			}
		}
	}
}
