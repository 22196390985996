.collapse {
	position: relative;
	margin-bottom: 15px;

	&__item {
		border-bottom: 1px solid #fff;

		&:first-child {
			border-top: 1px solid #fff;
		}

		&.is-show {
			.collapse {
				&__title {
					&::after {
						transform: rotate(180deg);
					}
				}
			}
		}
	}

	&__head {
		position: relative;
		padding: vw(20px, 750) 0;
		border-bottom: 1px solid #939598;
		user-select: none;
		cursor: pointer;

		@include minW(992) {
			padding: vw(20px) 0;
		}

		@include minW(1441) {
			padding: 20px 0;
		}
	}

	&__body {
		position: relative;
		overflow: hidden;
		height: 0;
		font-size: vw(24px, 750);

		@include minW(992) {
			font-size: vw(20px);
		}

		@include minW(1441) {
			font-size: 20px;
		}
	}

	&__content {
		padding: vw(20px) vw(100px) 0;
	}

	&__title {
		position: relative;
		font-size: vw(28px, 750);
		font-weight: 600;
		margin-bottom: 0;
		color: $turquoise-color;
		padding-right: vw(60px, 750);

		@include minW(992) {
			font-size: vw(24px);
			padding-right: vw(60px);
		}

		@include minW(1441) {
			font-size: 24px;
			padding-right: 60px;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			right: 3px;
			top: 50%;
			border-left: vw(12px, 750) solid transparent;
			border-right: vw(12px, 750) solid transparent;
			border-top: vw(16px, 750) solid $turquoise-color;

			@include minW(992) {
				border-left: vw(8px) solid transparent;
				border-right: vw(8px) solid transparent;
				border-top: vw(10px) solid $turquoise-color;
			}

			@include minW(1441) {
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-top: 10px solid $turquoise-color;
			}
		}
	}
}
