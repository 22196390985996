.title {
	font-family: $second-font;
	color: $turquoise-color;
	font-size: vw(48px, 750);
	margin-bottom: vw(15px, 750);

	@include minW(992) {
		font-size: vw(40px);
		margin-bottom: vw(15px);
	}

	@include minW(1441) {
		font-size: 40px;
		margin-bottom: 15px;
	}
}
