.itvc {
	position: relative;
	width: 100%;
	background: rgb(238, 253, 255);
	background: -moz-linear-gradient(top, rgba(238, 253, 255, 1) 0%, rgba(134, 212, 216, 1) 100%);
	background: -webkit-linear-gradient(top, rgba(238, 253, 255, 1) 0%, rgba(134, 212, 216, 1) 100%);
	background: linear-gradient(to bottom, rgba(238, 253, 255, 1) 0%, rgba(134, 212, 216, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#eefdff', endColorstr='#86d4d8', GradientType=0);
	padding: vw(100px, 750) 0 vw(150px, 750);
	overflow: hidden;

	@include minW(992) {
		padding: vw(100px) 0 vw(258px);
	}

	@include minW(1441) {
		padding: 100px 0 258px;
	}

	.title {
		text-align: center;
	}

	&__main {
		position: relative;
		z-index: 2;
	}

	&__body {
		position: relative;
		width: 100%;
		max-width: vw(750px);
		margin: 0 auto;

		@include minW(992) {
			max-width: 42.0833333333vw;
		}
	}

	&__video {
		border-radius: vw(20px);
		overflow: hidden;
		border: 5px solid #0AA6A2;
		background-color: #fff;
	}

	&__product {
		position: absolute;
		bottom: vw(-100px, 750);
		left: vw(-140px, 750);
		width: vw(160px, 750);

		@include minW(992) {
			bottom: vw(-220px);
			left: vw(-300px);
			width: vw(325px);
		}

		@include minW(1441) {
			bottom: -220px;
			left: -300px;
			width: 325px;
		}
	}

	&__button {
		display: flex;
		justify-content: center;
		padding: vw(30px) 0;

		@include minW(992) {
			padding: vw(20px) 0;
		}

		@include minW(1441) {
			padding: 20px 0;
		}
	}

	.stomach {
		position: absolute;
		width: vw(300px, 750);
		top: vw(250px, 750);
		right: 0;

		@include minW(992) {
			width: vw(538px);
			top: vw(250px);
		}

		@include minW(1441) {
			width: 538px;
			top: 250px;
		}
	}

	.xixi {
		position: absolute;
		top: vw(250px);
		right: 0;
		width: vw(295px);
		z-index: 2;
	}

	.cloud {
		position: absolute;
		width: 100%;

		&--top {
			top: 0;
		}

		&--bot {
			bottom: 0;
		}
	}
}
