.preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;
	background-color: $turquoise-color;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 999;

	&__icon {
		width: vw(162px, 750);
		height: vw(86px, 750);
		opacity: 0.4;
		background: url('../images/logo-footer.png') no-repeat center;
		background-size: cover;
		animation: loaderAnim 0.7s linear infinite alternate forwards;

		@include minW(992) {
			width: vw(162px);
			height: vw(86px);
		}

		@include minW(1441) {
			width: 162px;
			height: 86px;
		}
	}
}

@keyframes loaderAnim {
	to {
		opacity: 1;
		transform: scale3d(0.8, 0.8, 1);
	}
}
