.guides {
	position: relative;
	overflow: hidden;
	margin-bottom: vw(60px, 750);

	@include minW(992) {
		margin-bottom: vw(30px);
	}

	@include minW(1441) {
		margin-bottom: 30px;
	}

	&__head {
		display: flex;
		justify-content: center;
		margin-bottom: vw(30px, 750);

		@include minW(992) {
			margin-bottom: vw(15px);
		}

		@include minW(1441) {
			margin-bottom: 15px;
		}

		.img {
			width: vw(398px, 750);

			@include minW(992) {
				width: vw(398px);
			}

			@include minW(1441) {
				width: 398px;
			}
		}
	}

	&__body {
		position: relative;

		.line {
			&::before {
				content: '';
				display: none;
				background: url('../images/guides/line.png') no-repeat center;
				background-size: cover;
				position: absolute;
				left: 50%;
				transform: translateX(-50%);

				@include minW(992) {
					top: vw(90px);
					display: block;
					width: vw(971px);
					height: vw(75px);
				}

				@include minW(1441) {
					top: 90px;
					width: 971px;
					height: 75px;
				}
			}
		}

		.row {
			&__col:nth-child(2n+1) {
				@include minW(992) {
					margin-top: vw(50px);
				}

				@include minW(1441) {
					margin-top: 50px;
				}
			}
		}
	}
}
