.questions {
	position: relative;
	z-index: 3;

	&__main {
		position: relative;
		overflow: hidden;
		z-index: 2;
	}

	&__head {
		display: flex;
		justify-content: center;
		margin-bottom: vw(30px, 750);

		@include minW(992) {
			margin-bottom: vw(20px);
		}

		@include minW(1441) {
			margin-bottom: 20px;
		}

		.img {
			width: vw(401px, 750);

			@include minW(992) {
				width: vw(401px);
			}

			@include minW(1441) {
				width: 401px;
			}
		}
	}

	&__product {
		display: flex;
		justify-content: center;

		.img {
			width: vw(327px, 750);

			@include minW(992) {
				width: vw(327px);
			}

			@include minW(1441) {
				width: 327px;
			}
		}
	}

	.cloud {
		position: absolute;
		width: 100%;
		bottom: vw(-124px, 640);

		@include minW(992) {
			bottom: vw(-242px);
		}

		@include minW(1441) {
			bottom: -242px;
		}
	}
}
