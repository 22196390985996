.banner {
	position: relative;
	background: -moz-linear-gradient(top, rgba(99, 215, 212, 0) 0%, rgba(99, 215, 212, 1) 65%, rgba(139, 229, 227, 1) 100%);
	background: -webkit-linear-gradient(top, rgba(99, 215, 212, 0) 0%, rgba(99, 215, 212, 1) 65%, rgba(139, 229, 227, 1) 100%);
	background: linear-gradient(to bottom, rgba(99, 215, 212, 0) 0%, rgba(99, 215, 212, 1) 65%, rgba(139, 229, 227, 1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#0063d7d4', endColorstr='#8be5e3', GradientType=0);

	&__main {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}

	.baby {
		position: absolute;
		right: 0;
		bottom: 0;
		z-index: 3;
		width: vw(604px);

		@include minW(992) {
			width: vw(604px);
		}

		@include minW(1441) {
			width: 604px;
		}
	}

	.tagline {
		position: absolute;
		top: vw(110px, 750);
		left: vw(105px, 750);
		width: vw(250px, 750);
		z-index: 3;

		@include minW(992) {
			width: vw(546px);
			top: vw(130px);
			left: vw(105px);
		}

		@include minW(1441) {
			width: 546px;
			top: 130px;
			left: 105px;
		}
	}

	.product {
		position: absolute;
		top: vw(200px, 750);
		left: vw(120px, 750);
		width: vw(200px, 750);
		z-index: 3;

		@include minW(992) {
			top: vw(292px);
			left: vw(146px);
			width: vw(439px);
		}

		@include minW(1441) {
			top: 292px;
			left: 146px;
			width: 439px;
		}
	}

	.cloud {
		position: absolute;
		width: 100%;
		bottom: 0;
	}

	.button {
		display: flex;
		justify-content: center;
		position: absolute;
		bottom: vw(-10px, 750);
		right: vw(40px, 750);
		z-index: 3;

		@include minW(992) {
			bottom: vw(-20px);
			right: vw(200px);
		}

		@include minW(1441) {
			bottom: -20px;
			right: 200px;
		}

		.btn {
			width: vw(150px, 750);
			height: vw(45px, 750);
			font-size: vw(24px, 750);
			background: url('../images/reason/bg-button.png') no-repeat center;
			background-size: cover;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: $second-font;
			letter-spacing: -2%;
			color: $white-color;
			transition: all 0.3s ease-out;

			@include minW(992) {
				width: vw(120px);
				height: vw(36px);
				font-size: vw(16px);
			}

			@include minW(1441) {
				width: 120px;
				height: 36px;
				font-size: 16px;
			}

			@include hover {
				transform: scale(1.05);
			}
		}
	}
}
