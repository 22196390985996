.footer {
	position: relative;
	background-color: $turquoise-color;
	color: $white-color;
	padding: vw(40px, 640) 0;

	@include minW(992) {
		padding: vw(30px) 0;
	}

	@include minW(1441) {
		padding: 30px 0;
	}

	&__body {
		.row {
			align-items: center;
		}
	}

	&__logo {
		display: block;
		width: vw(285px, 640);

		@include minW(992) {
			width: vw(285px);
		}

		@include minW(1441) {
			width: 285px;
		}
	}

	&__content {
		margin-bottom: vw(30px, 750);

		@include minW(992) {
			margin-bottom: 0;
		}
	}

	&__mega {
		display: flex;
		align-items: center;

		.img {
			&:first-child {
				margin-right: 10px;
				width: vw(200px, 750);

				@include minW(992) {
					width: vw(150px);
				}

				@include minW(1441) {
					width: 150px;
				}
			}

			&:last-child {
				width: vw(180px, 750);

				@include minW(992) {
					width: vw(134px);
				}

				@include minW(1441) {
					width: 134px;
				}
			}
		}
	}

	&__title {
		margin-bottom: 0;
		font-size: vw(20px, 640);
		font-weight: 600;

		@include minW(992) {
			font-size: vw(16px);
		}

		@include minW(1441) {
			font-size: 16px;
		}
	}

	&__desc {
		font-size: vw(20px, 640);
		margin-bottom: vw(30px, 750);

		@include minW(992) {
			font-size: vw(16px);
			margin-bottom: vw(15px);
		}

		@include minW(1441) {
			font-size: 16px;
			margin-bottom: 15px;
		}

		&:last-child {
			margin-bottom: 0;
		}

		span {
			display: block;
		}
	}

	.row {
		&__col {
			&:first-child {
				order: 1;

				@include minW(992) {
					order: 0;
				}
			}

			&:last-child {
				order: 0;

				@include minW(992) {
					order: 1;
				}
			}
		}
	}
}
