.pagination {
	display: flex;
	align-items: center;
	justify-content: center;
	list-style: none;
	margin-bottom: 0;

	.page {
		&-item {
			margin: 0 5px;

			&:first-child,
			&:last-child,
			&:nth-child(2),
			&:nth-last-child(2) {
				.page {
					&-link {
						font-size: 0;

						&::before {
							display: block;
							font-size: vw(30px, 750);

							@include minW(992) {
								font-size: vw(20px);
							}

							@include minW(1441) {
								font-size: 20px;
							}
						}
					}
				}
			}

			&:first-child {
				.page {
					&-link {
						&::before {
							content: '\00AB';
						}
					}
				}
			}

			&:nth-child(2) {
				.page {
					&-link {
						&::before {
							content: '\2039';
						}
					}
				}
			}

			&:last-child {
				.page {
					&-link {
						&::before {
							content: '\00BB';
						}
					}
				}
			}

			&:nth-last-child(2) {
				.page {
					&-link {
						&::before {
							content: '\203A';
						}
					}
				}
			}

			&.active {
				border: 1px solid $turquoise-color;
				border-radius: 50%;

				.page {
					&-link {
						color: $turquoise-color;
					}
				}
			}
		}

		&-link {
			width: vw(30px, 750);
			height: vw(30px, 750);
			font-size: vw(24px, 750);
			display: flex;
			align-items: center;
			justify-content: center;

			@include minW(992) {
				width: vw(24px);
				height: vw(24px);
				font-size: vw(18px);
			}

			@include minW(1441) {
				width: 24px;
				height: 24px;
				font-size: 18px;
			}
		}
	}
}
