.home-kv {
	position: relative;

	&--dt {
		display: none;

		@include minW(992) {
			display: block;
		}
	}

	&--tb {
		display: none;

		@include minW(640) {
			display: block;
		}

		@include minW(992) {
			display: none;
		}
	}

	&--mb {
		display: block;

		@include minW(640) {
			display: none;
		}
	}
}
