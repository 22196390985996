.d-none {
	display: none;
}

.image {
	&--dt {
		display: none;

		@include minW(992) {
			display: block;
		}
	}

	&--mb {
		display: block;

		@include minW(992) {
			display: none;
		}
	}
}
