.btn {
	display: inline-flex;
	color: $turquoise-color;
	line-height: 1.2;
	position: relative;
	transition: all 0.3s ease-out;

	&--primary {
		background-color: $turquoise-color;
		text-transform: uppercase;
		font-size: vw(20px, 750);
		font-weight: 700;
		color: $white-color;
		border: 1px solid $turquoise-color;
		border-radius: vw(30px, 750);
		padding: vw(5px, 750) vw(20px, 750);

		@include minW(992) {
			font-size: vw(20px);
			border-radius: vw(30px);
			padding: vw(5px) vw(30px);
		}

		@include minW(1441) {
			font-size: 20px;
			border-radius: 30px;
			padding: 5px 30px;
		}

		@include hover {
			background-color: $white-color;
			color: $turquoise-color;
		}
	}

	&--secondary {
		background-color: $white-color;
		text-transform: uppercase;
		font-size: vw(20px, 750);
		font-weight: 700;
		color: $turquoise-color;
		border: 1px solid $turquoise-color;
		border-radius: vw(30px, 750);
		padding: vw(5px, 750) vw(20px, 750);

		@include minW(992) {
			font-size: vw(20px);
			border-radius: vw(30px);
			padding: vw(5px) vw(30px);
		}

		@include minW(1441) {
			font-size: 20px;
			border-radius: 30px;
			padding: 5px 30px;
		}

		@include hover {
			background-color: $turquoise-color;
			color: $white-color;
		}
	}

	&--link {
		border: 0;

		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 1px;
			background-color: $turquoise-color;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translateX(-50%);
			transition: width 0.3s ease-out;
		}

		&:hover {
			&::after {
				width: 0;
			}
		}
	}
}
