.card {
	position: relative;
	font-size: vw(24px, 750);
	border-radius: vw(15px, 750);

	@include minW(992) {
		font-size: vw(18px);
		border-radius: vw(15px);
	}

	@include minW(1441) {
		font-size: 18px;
		border-radius: 15px;
	}

	&--expert {
		background-color: $white-color;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		padding-top: 0;
		margin-bottom: 16px;

		@include minW(992) {
			display: flex;
			align-items: center;
			padding: 0;
		}

		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(233, 253, 255, 1) 100%);
			background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(233, 253, 255, 1) 100%);
			background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(233, 253, 255, 1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#e9fdff', GradientType=0);
			border-radius: 0 0 vw(15px) vw(15px);

			@include minW(992) {
				height: 50%;
			}

		}

		.card {
			&__thumb {
				margin-top: vw(-90px, 750);
				padding: 0 vw(60px, 750);
				position: absolute;
				top: 0;

				@include minW(992) {
					position: relative;
					top: auto;
					width: 30%;
					margin-top: vw(-50px);
					padding: 0 vw(20px);
				}

				@include minW(1441) {
					margin-top: -50px;
					padding: 0 20px;
				}
			}

			&__body {
				position: relative;
				padding: vw(30px, 750);
				z-index: 2;

				@include minW(992) {
					width: 100%;
					padding: vw(30px);
				}

				@include minW(1441) {
					padding: 30px;
				}
			}

			&__title {
				font-size: vw(24px, 750);
				font-weight: 400;
				margin-bottom: vw(15px, 750);

				@include minW(992) {
					font-size: vw(20px);
					margin-bottom: vw(15px);
				}

				@include minW(1441) {
					font-size: 20px;
					margin-bottom: 15px;
				}
			}

			&__name {
				font-size: vw(24px, 750);
				font-weight: 700;
				display: block;

				@include minW(992) {
					font-size: vw(24px);
				}

				@include minW(1441) {
					font-size: 24px;
				}
			}

			&__desc {
				margin-bottom: 0;
			}
		}
	}

	&--hot-news {
		overflow: hidden;
		box-shadow: 0px 4px 4px 0px #00000040;

		.card {
			&__body {
				background-color: $white-color;
				color: $turquoise-color;
				padding: vw(30px, 750);

				@include minW(992) {
					padding: vw(25px);
				}

				@include minW(1441) {
					padding: 25px;
				}
			}

			&__desc {
				font-size: vw(24px, 750);

				@include minW(992) {
					font-size: vw(18px);
				}

				@include minW(1441) {
					font-size: 18px;
				}
			}
		}
	}

	&--reason {
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.card {
			&__thumb {
				margin: 0 auto;
				transition: transform 0.3s ease-out;

				@include hover {
					transform: scale(1.1);
				}
			}

			&__body {
				padding: vw(15px) 0;
			}

			&__desc {
				span {
					display: block;
				}

				strong {
					font-weight: 600;
					color: $turquoise-color;
				}
			}
		}
	}

	&--method {
		text-align: center;
		display: flex;
		flex-direction: column;
		justify-content: center;

		.card {
			&__thumb {
				margin: 0 auto;
				transition: transform 0.3s ease-out;

				@include hover {
					transform: scale(1.1);
				}
			}

			&__body {
				padding: vw(15px) 0;
			}

			&__desc {
				font-weight: 700;
				color: $black-color;

				span {
					display: block;
				}
			}
		}
	}

	&--news {
		margin-bottom: 30px;

		.card {
			&__thumb {
				overflow: hidden;
				border-radius: vw(30px, 750);

				@include minW(992) {
					border-radius: vw(20px);
				}

				@include minW(1441) {
					border-radius: 20px;
				}
			}

			&__body {
				padding: vw(30px) 0;
			}

			&__title {
				font-size: vw(32px, 750);
				margin-bottom: vw(15px, 750);

				@include minW(992) {
					font-size: vw(24px);
					margin-bottom: vw(15px);
				}

				@include minW(1441) {
					font-size: 24px;
					margin-bottom: 15px;
				}
			}

			&__desc {
				margin-bottom: vw(15px, 750);

				@include minW(992) {
					margin-bottom: vw(15px);
				}

				@include minW(1441) {
					margin-bottom: 15px;
				}
			}
		}
	}

	&--guide {
		text-align: center;

		.card {
			&__thumb {
				display: flex;
				justify-content: center;
				transition: transform 0.3s ease-out;

				@include hover {
					transform: scale(1.1);
				}
			}

			&__desc {
				padding: vw(15px);

				span {
					color: $turquoise-color;
				}

				strong {
					color: $turquoise-color;
				}
			}
		}
	}

	&--support {
		text-align: center;

		.card {
			&__thumb {
				display: flex;
				justify-content: center;
				transition: transform 0.3s ease-out;

				@include hover {
					transform: scale(1.1);
				}

				.img {
					width: 100%;

					@include minW(992) {
						width: vw(286px);
					}

					@include minW(1441) {
						width: 286px;
					}
				}
			}

			&__body {
				padding: vw(15px, 750) 0;

				@include minW(992) {
					padding: vw(15px) vw(50px);
				}

				@include minW(1441) {
					padding: 15px 50px;
				}
			}

			&__desc {
				font-size: vw(24px, 750);
				font-weight: 600;
				line-height: 1.2;
				color: $black-color;

				@include minW(992) {
					font-size: vw(24px);
				}

				@include minW(1441) {
					font-size: 24px;
				}

				span {
					font-size: vw(14px, 750);
					text-transform: uppercase;
					margin-top: 5px;
					display: block;

					@include minW(992) {
						font-size: vw(12px);
					}

					@include minW(1441) {
						font-size: 12px;
					}
				}
			}
		}
	}

	&--uses {
		background: rgb(255, 255, 255);
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(228, 255, 254, 1) 100%);
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(228, 255, 254, 1) 100%);
		background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(228, 255, 254, 1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff', endColorstr='#e4fffe', GradientType=0);
		box-shadow: 0px 2px 4px 0px #00000040, 0px -1px 4px 0px #0000001F inset;

		@include hover {

			.card {
				&__body {
					font-weight: 700;
					text-shadow: 0 0 8px #00000040;
				}
			}
		}

		.card {
			&__body {
				padding: vw(30px, 750);
				font-size: vw(24px, 750);
				color: $turquoise-color;
				transition: all 0.3s ease-out;

				@include minW(992) {
					padding: vw(50px);
					font-size: vw(36px);
				}

				@include minW(1441) {
					padding: 50px;
					font-size: 36px;
				}

				p {
					margin-bottom: 0;
				}
			}
		}
	}

	&__date {
		font-size: vw(22px, 750);
		font-style: italic;

		@include minW(992) {
			font-size: vw(18px);
		}

		@include minW(1441) {
			font-size: 18px;
		}
	}

	&__title {
		color: $turquoise-color;
		font-size: vw(34px, 750);

		@include minW(992) {
			font-size: vw(40px);
		}

		@include minW(1441) {
			font-size: 40px;
		}
	}

	&__thumb {
		position: relative;
	}

	&__link {
		text-align: right;
	}
}
