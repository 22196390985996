.scroll-top {
	position: fixed;
	bottom: vw(15px, 640);
	right: vw(15px, 640);
	width: vw(170px, 640);
	opacity: 0;
	cursor: pointer;
	transition: all 0.3s ease-out;
	z-index: 9;

	@include minW(992) {
		bottom: vw(15px);
		right: vw(15px);
		width: vw(170px);
		transform: translateX(vw(170px));
	}

	@include minW(1441) {
		bottom: 15px;
		right: 15px;
		width: 170px;
		transform: translateX(170px);
	}

	&.is-show {
		opacity: 1;
		transform: translateX(0);
	}
}
