.testimonial {
	position: relative;
	overflow: hidden;

	.title {
		text-align: center;
	}

	// xoa khi du section
	&.is-short {
		margin-top: vw(-250px, 750);
		z-index: 2;

		@include minW(992) {
			margin-top: vw(-400px);
		}

		@include minW(1441) {
			margin-top: -400px;
		}
	}

	&__body {
		position: relative;
		overflow: hidden;
	}

	&__product {
		display: flex;
		justify-content: center;
		margin-top: vw(60px, 750);

		@include minW(992) {
			margin-top: 0;
		}

		.img {
			width: vw(345px, 750);

			@include minW(992) {
				width: vw(345px);
			}

			@include minW(1441) {
				width: 345px;
			}
		}
	}

	.row {
		align-items: center;
	}

	.scroll-wrapper {
		max-height: vw(750px, 750);

		@include minW(992) {
			max-height: vw(578px);
		}

		@include minW(1441) {
			max-height: 578px;
		}
	}

	.scroll-content {
		padding-right: vw(50px, 750);

		@include minW(992) {
			padding-right: vw(50px);
		}

		@include minW(1441) {
			padding-right: 50px;
		}
	}

}

.comment {
	position: relative;
	margin-left: vw(150px, 750);
	margin: vw(70px, 750) 0 vw(115px, 750) vw(140px, 750);
	padding: vw(20px, 750) vw(20px, 750) vw(20px, 750) vw(110px, 750);
	border-radius: vw(30px, 750);
	border: 4px solid rgba($turquoise-color, 0.3);
	background-color: #fff;

	@include minW(992) {
		margin-left: vw(150px);
		margin: vw(50px) 0 vw(95px) vw(140px);
		padding: vw(20px) vw(20px) vw(20px) vw(110px);
		border-radius: vw(30px);
	}

	@include minW(1441) {
		margin-left: 150px;
		margin-top: 50px 0 95px 140px;
		padding: 20px 20px 20px 110px;
		border-radius: 30px;
	}

	&:last-child {
		margin-bottom: vw(40px, 750);

		@include minW(992) {
			margin-bottom: vw(40px);
		}

		@include minW(1441) {
			margin-bottom: 40px;
		}
	}

	&__main {
		position: relative;
		z-index: 2;
	}

	&::after {
		content: "";
		position: absolute;
		width: vw(60px, 750);
		height: vw(60px, 750);
		border-top: 0px solid rgba($turquoise-color, 0.3);
		border-right: 4px solid rgba($turquoise-color, 0.3);
		border-bottom: 4px solid rgba($turquoise-color, 0.3);
		border-left: 0px solid rgba($turquoise-color, 0.3);
		top: 100%;
		right: 10%;
		transform: rotate(45deg);
		margin-top: vw(-30px, 750);
		background-color: #fff;

		@include minW(992) {
			width: vw(50px);
			height: vw(50px);
			margin-top: vw(-25px);
		}

		@include minW(1441) {
			width: 50px;
			height: 50px;
			margin-top: -25px;
		}
	}

	&__thumb {
		position: absolute;
		width: vw(234px, 750);
		top: vw(-70px, 750);
		left: vw(-250px, 750);

		@include minW(992) {
			width: vw(234px);
			top: vw(-70px);
			left: vw(-250px);
		}

		@include minW(1441) {
			width: 234px;
			top: -70px;
			left: -250px;
		}
	}

	&__content {
		margin-bottom: 0;
		font-size: vw(24px, 750);

		@include minW(992) {
			font-size: vw(20px);
		}

		@include minW(1441) {
			font-size: 20px;
		}
	}

	&__name {
		font-size: vw(24px, 750);
		color: $turquoise-color;
		margin-bottom: 0;

		@include minW(992) {
			font-size: vw(24px);
		}

		@include minW(1441) {
			font-size: 24px;
		}
	}

	&__address {
		font-size: vw(24px, 750);
		color: $turquoise-color;
		margin-bottom: 0;

		@include minW(992) {
			font-size: vw(20px);
		}

		@include minW(1441) {
			font-size: 20px;
		}
	}
}
