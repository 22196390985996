.uses {
	position: relative;
	overflow: hidden;
	text-align: center;
	padding: vw(50px, 750) 0 vw(100px, 750);

	@include minW(992) {
		padding: vw(50px) 0;
	}

	@include minW(1441) {
		padding: 50px 0;
	}

	&__main {
		position: relative;
		padding: 0 15px;
		margin: 0 auto;
		z-index: 2;

		@include minW(992) {
			width: vw(982px);
			padding: 0 vw(15px);
		}

		@include minW(1441) {
			width: 982px;
			padding: 0 15px;
		}
	}

	&__head {
		display: flex;
		justify-content: center;
		margin-bottom: vw(30px, 750);

		@include minW(992) {
			margin-bottom: vw(15px);
		}

		@include minW(1441) {
			margin-bottom: 15px;
		}

		.img {
			width: vw(223px, 750);

			@include minW(992) {
				width: vw(223px);
			}

			@include minW(1441) {
				width: 223px;
			}
		}
	}

	.cloud {
		position: absolute;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
	}

	.row {
		margin: 0 -8px;

		@include minW(992) {
			margin: 0 -15px;
		}

		&__col {
			padding: 0 8px;

			@include minW(992) {
				padding: 0 15px;
			}
		}
	}
}
