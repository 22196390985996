.news {
	position: relative;
	z-index: 3;

	&__main {
		position: relative;
		z-index: 2;
	}

	&__head {
		padding: vw(40px, 750) 0;

		@include minW(992) {
			padding: vw(40px) 0;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		@include minW(1441) {
			padding: 40px 0;
		}

		.title {
			margin-bottom: vw(20px, 750);

			@include minW(992) {
				margin-bottom: 0;
			}
		}
	}

	&__foot {
		padding: vw(30px) 0;
	}

	.cloud {
		position: absolute;
		width: 100%;
		bottom: vw(-124px, 750);

		@include minW(992) {
			bottom: vw(-242px);
		}

		@include minW(1441) {
			bottom: -242px;
		}
	}
}

.form {
	display: flex;
	align-items: center;
	margin: 0 -15px;

	&__group {
		position: relative;
		flex-basis: 50%;
		padding: 0 15px;

		@include minW(992) {
			margin-left: vw(15px);
			display: flex;
			flex-basis: auto;
			align-items: center;
			padding: 0 vw(15px);
		}

		@include minW(1441) {
			margin-left: 15px;
			padding: 0 15px;
		}
	}

	&__label {
		margin-bottom: 0;
		color: $turquoise-color;
		font-size: vw(24px, 750);
		font-weight: 600;
		display: block;

		@include minW(992) {
			font-size: vw(24px);
			margin-right: vw(10px);
		}


		@include minW(1441) {
			font-size: 24px;
			margin-right: 10px;
		}
	}

	&__control {
		border: 0;
		border-bottom: 1px solid #4F4F4F;
		font-size: vw(24px, 750);
		outline: 0;
		width: 100%;
		border-radius: 0;
		-webkit-appearance: none;

		@include minW(992) {
			font-size: vw(20px);
		}

		@include minW(1441) {
			font-size: 20px;
		}
	}

	&__inline {
		position: relative;
		padding-right: vw(30px, 750);

		@include minW(992) {
			padding-right: vw(40px);
		}

		@include minW(1441) {
			padding-right: 40px;
		}
	}

	.select {
		display: block;
		width: 100%;
		max-width: 100%;
		position: relative;
		vertical-align: top;

		@include minW(992) {
			width: auto;
			display: inline-block;
		}

		&::after {
			content: '';
			display: block;
			position: absolute;
			right: 3px;
			top: 50%;
			transform: translateY(-50%);
			border-left: vw(12px, 750) solid transparent;
			border-right: vw(12px, 750) solid transparent;
			border-top: vw(16px, 750) solid $turquoise-color;

			@include minW(992) {
				border-left: vw(8px) solid transparent;
				border-right: vw(8px) solid transparent;
				border-top: vw(10px) solid $turquoise-color;
			}

			@include minW(1441) {
				border-left: 8px solid transparent;
				border-right: 8px solid transparent;
				border-top: 10px solid $turquoise-color;
			}
		}
	}

	select {
		border: 0;
		border-bottom: 1px solid #4F4F4F;
		font-size: vw(24px, 750);
		outline: 0;
		width: 100%;
		border-radius: 0;
		padding-right: vw(30px, 750);
		-webkit-appearance: none;
		background-color: transparent;
		color: #000;

		@include minW(992) {
			font-size: vw(20px);
			padding-right: vw(30px);
			width: auto;
		}

		@include minW(1441) {
			font-size: 20px;
			padding-right: 30px;
		}
	}

	button {
		border: 0;
		outline: 0;
		display: inline-block;
		background-color: transparent;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	.icon {
		display: block;
		width: vw(27px, 750);
		height: vw(27px, 750);
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;

		@include minW(992) {
			width: vw(27px);
			height: vw(27px);
		}

		@include minW(1441) {
			width: 27px;
			height: 27px;
		}

		&--search {
			background-image: url('../images/icon-search.png');
		}
	}
}
