:root {
	--animate-duration: 1s;
	--animate-delay: 1s;
	--animate-repeat: 1;
}

.animate__animated {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-duration: var(--animate-duration);
	animation-duration: var(--animate-duration);
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.animate__animated.animate__infinite {
	-webkit-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.animate__animated.animate__repeat-1 {
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	-webkit-animation-iteration-count: var(--animate-repeat);
	animation-iteration-count: var(--animate-repeat);
}

.animate__animated.animate__repeat-2 {
	-webkit-animation-iteration-count: calc(1 * 2);
	animation-iteration-count: calc(1 * 2);
	-webkit-animation-iteration-count: calc(var(--animate-repeat) * 2);
	animation-iteration-count: calc(var(--animate-repeat) * 2);
}

.animate__animated.animate__repeat-3 {
	-webkit-animation-iteration-count: calc(1 * 3);
	animation-iteration-count: calc(1 * 3);
	-webkit-animation-iteration-count: calc(var(--animate-repeat) * 3);
	animation-iteration-count: calc(var(--animate-repeat) * 3);
}

.animate__animated.animate__delay-1s {
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
	-webkit-animation-delay: var(--animate-delay);
	animation-delay: var(--animate-delay);
}

.animate__animated.animate__delay-2s {
	-webkit-animation-delay: calc(1s * 2);
	animation-delay: calc(1s * 2);
	-webkit-animation-delay: calc(var(--animate-delay) * 2);
	animation-delay: calc(var(--animate-delay) * 2);
}

.animate__animated.animate__delay-3s {
	-webkit-animation-delay: calc(1s * 3);
	animation-delay: calc(1s * 3);
	-webkit-animation-delay: calc(var(--animate-delay) * 3);
	animation-delay: calc(var(--animate-delay) * 3);
}

.animate__animated.animate__delay-4s {
	-webkit-animation-delay: calc(1s * 4);
	animation-delay: calc(1s * 4);
	-webkit-animation-delay: calc(var(--animate-delay) * 4);
	animation-delay: calc(var(--animate-delay) * 4);
}

.animate__animated.animate__delay-5s {
	-webkit-animation-delay: calc(1s * 5);
	animation-delay: calc(1s * 5);
	-webkit-animation-delay: calc(var(--animate-delay) * 5);
	animation-delay: calc(var(--animate-delay) * 5);
}

.animate__animated.animate__faster {
	-webkit-animation-duration: calc(1s / 2);
	animation-duration: calc(1s / 2);
	-webkit-animation-duration: calc(var(--animate-duration) / 2);
	animation-duration: calc(var(--animate-duration) / 2);
}

.animate__animated.animate__fast {
	-webkit-animation-duration: calc(1s * 0.8);
	animation-duration: calc(1s * 0.8);
	-webkit-animation-duration: calc(var(--animate-duration) * 0.8);
	animation-duration: calc(var(--animate-duration) * 0.8);
}

.animate__animated.animate__slow {
	-webkit-animation-duration: calc(1s * 2);
	animation-duration: calc(1s * 2);
	-webkit-animation-duration: calc(var(--animate-duration) * 2);
	animation-duration: calc(var(--animate-duration) * 2);
}

.animate__animated.animate__slower {
	-webkit-animation-duration: calc(1s * 3);
	animation-duration: calc(1s * 3);
	-webkit-animation-duration: calc(var(--animate-duration) * 3);
	animation-duration: calc(var(--animate-duration) * 3);
}

@media print,
(prefers-reduced-motion: reduce) {
	.animate__animated {
		-webkit-animation-duration: 1ms !important;
		animation-duration: 1ms !important;
		-webkit-transition-duration: 1ms !important;
		transition-duration: 1ms !important;
		-webkit-animation-iteration-count: 1 !important;
		animation-iteration-count: 1 !important;
	}

	.animate__animated[class*='Out'] {
		opacity: 0;
	}
}

@-webkit-keyframes pulse {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	50% {
		-webkit-transform: scale3d(1.05, 1.05, 1.05);
		transform: scale3d(1.05, 1.05, 1.05);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

@keyframes pulse {
	from {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}

	50% {
		-webkit-transform: scale3d(1.05, 1.05, 1.05);
		transform: scale3d(1.05, 1.05, 1.05);
	}

	to {
		-webkit-transform: scale3d(1, 1, 1);
		transform: scale3d(1, 1, 1);
	}
}

.animate__pulse {
	-webkit-animation-name: pulse;
	animation-name: pulse;
	-webkit-animation-timing-function: ease-in-out;
	animation-timing-function: ease-in-out;
}

@-webkit-keyframes swing {
	20% {
		-webkit-transform: rotate3d(0, 0, 1, 15deg);
		transform: rotate3d(0, 0, 1, 15deg);
	}

	40% {
		-webkit-transform: rotate3d(0, 0, 1, -10deg);
		transform: rotate3d(0, 0, 1, -10deg);
	}

	60% {
		-webkit-transform: rotate3d(0, 0, 1, 5deg);
		transform: rotate3d(0, 0, 1, 5deg);
	}

	80% {
		-webkit-transform: rotate3d(0, 0, 1, -5deg);
		transform: rotate3d(0, 0, 1, -5deg);
	}

	to {
		-webkit-transform: rotate3d(0, 0, 1, 0deg);
		transform: rotate3d(0, 0, 1, 0deg);
	}
}

@keyframes swing {
	20% {
		-webkit-transform: rotate3d(0, 0, 1, 15deg);
		transform: rotate3d(0, 0, 1, 15deg);
	}

	40% {
		-webkit-transform: rotate3d(0, 0, 1, -10deg);
		transform: rotate3d(0, 0, 1, -10deg);
	}

	60% {
		-webkit-transform: rotate3d(0, 0, 1, 5deg);
		transform: rotate3d(0, 0, 1, 5deg);
	}

	80% {
		-webkit-transform: rotate3d(0, 0, 1, -5deg);
		transform: rotate3d(0, 0, 1, -5deg);
	}

	to {
		-webkit-transform: rotate3d(0, 0, 1, 0deg);
		transform: rotate3d(0, 0, 1, 0deg);
	}
}

.animate__swing {
	-webkit-transform-origin: center;
	transform-origin: center;
	-webkit-animation-name: swing;
	animation-name: swing;
}
