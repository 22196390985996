.hot-news {
	position: relative;
	background: -moz-linear-gradient(top, rgba(166, 238, 241, 0) 0%, rgba(104, 225, 231, 1) 50%, rgba(228, 255, 254, 0) 100%);
	background: -webkit-linear-gradient(top, rgba(166, 238, 241, 0) 0%, rgba(104, 225, 231, 1) 50%, rgba(228, 255, 254, 0) 100%);
	background: linear-gradient(to bottom, rgba(166, 238, 241, 0) 0%, rgba(104, 225, 231, 1) 50%, rgba(228, 255, 254, 0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00a6eef1', endColorstr='#00e4fffe', GradientType=0);
	padding-top: vw(10px, 750);
	z-index: 2;

	@include minW(992) {
		padding-top: vw(10px);
	}

	@include minW(1441) {
		padding-top: 10px;
	}

	.title {
		text-align: center;
	}

	&__main {
		position: relative;
		overflow: hidden;
		z-index: 2;

		@include minW(992) {
			padding: 0 vw(192px);
		}

		@include minW(1441) {
			padding: 0 192px;
		}
	}

	&__foot {
		display: flex;
		justify-content: center;
		padding: vw(20px, 750);

		@include minW(992) {
			padding: vw(20px) 0;
		}

		@include minW(1441) {
			padding: 20px 0;
		}
	}

	.slick {
		&-list {
			padding: 0 vw(150px, 750) !important;

			@include minW(992) {
				padding: 0 !important;
				margin: 0 vw(-18px);
			}

			@include minW(1441) {
				margin: 0 -18px;
			}
		}

		&-slide {
			padding: vw(20px, 750) vw(32px, 750);

			@include minW(992) {
				padding: vw(18px);
			}

			@include minW(1441) {
				padding: 18px;
			}
		}

		&-next {
			right: vw(140px, 750);

			@include minW(992) {
				right: vw(-180px);
			}

			@include minW(1441) {
				right: -180px;
			}
		}

		&-prev {
			left: vw(140px, 750);

			@include minW(992) {
				left: vw(-180px);
			}

			@include minW(1441) {
				left: -180px;
			}
		}

		&__item {
			padding: 0 16px;
			margin: 0 auto;
			max-width: 500px;
		}
	}

	.cloud {
		position: absolute;
		width: 100%;

		&--top {
			top: 0;
		}

		&--bot {
			bottom: vw(-255px);
		}
	}
}
